<template>
  <div class="flex flex-col justify-center w-full">
    <div class="flex flex-col">
      <Logo invert class="w-44 self-center" />
      <div class="my-6 text-3xl font-semibold text-gray-900">Log in with magic link</div>
      <div v-if="!emailSent && !shouldCollectEmail" class="mt-3 text-gray-600">
        We will send you an email with a link to authenticate.
      </div>

      <div v-if="shouldCollectEmail" class="">Please enter your email address for confirmation.</div>
    </div>
    <UForm v-if="!emailSent" class="mt-8" :schema="schema" :state="state" @submit="onsubmit">
      <UFormGroup label="Email" name="email">
        <UInput
          v-model="state.email"
          :disabled="sendingLink || loggingIn"
          class="[&>input]:!px-3.5 [&>input]:!py-2.5"
          placeholder="Enter your email"
        />
      </UFormGroup>

      <div class="mt-6">
        <UButton
          :loading="sendingLink || loggingIn"
          :disabled="!canSubmitForm"
          class="flex justify-center items-center w-full h-10"
          type="submit"
        >
          {{ loggingIn ? 'Logging In...' : shouldCollectEmail ? 'Log in' : 'Send link' }}
        </UButton>
      </div>
    </UForm>
    <div v-else>
      <div>
        <p>We have sent you an email with a link to authenticate to {{ state.email }}.</p>
        <p><strong>Please check your inbox</strong> and click the link to log in.</p>
      </div>
    </div>
    <p v-if="firebaseError" class="w-full mt-2 text-left text-red-500 text-sm">{{ firebaseError }}</p>
  </div>
</template>

<script setup lang="ts">
import { object, string } from 'yup'
import type { FormSubmitEvent } from '#ui/types'
import { useFirebaseHumanError } from '@hiyield/firebase-errors-human-readable'
import { getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth'

const firebaseError = ref<string>()
const state = reactive<{ email?: string }>({
  email: undefined
})

const schema = object({
  email: string().email('Invalid email').required('Required')
})

const canSubmitForm = computed(() => {
  return schema.isValidSync(state)
})

const emailSent = ref(false)

const sendingLink = ref(false)
const sendLink = async (event: FormSubmitEvent<{ email: string }>) => {
  try {
    firebaseError.value = ''
    const { email } = event.data
    sendingLink.value = true
    // log in the user
    const fullUrl = new URL(window.location.href)
    const continueUrl = fullUrl.searchParams.get('continueUrl')
    const continueUrlFull = continueUrl ? new URL(fullUrl.searchParams.get('continueUrl') || '/') : undefined
    let urlToRedirectTo = ''

    // we never want to redirect to the auth page or we will get stuck in a loop
    if (fullUrl.pathname !== '/auth/action') {
      urlToRedirectTo = fullUrl.href
    } else if (continueUrlFull && continueUrlFull.pathname !== '/auth/action') {
      urlToRedirectTo = continueUrlFull.href
    } else {
      urlToRedirectTo = '/admin/dashboard'
    }

    await sendSignInLinkToEmail(getAuth(), email, {
      url: urlToRedirectTo,
      handleCodeInApp: true
    })
    // save the email to local storage so we can use it later
    window.localStorage.setItem('emailForSignIn', email)
    emailSent.value = true
    sendingLink.value = false
  } catch (e) {
    console.error(e)
    const firebaseHumanError = useFirebaseHumanError({
      'auth/invalid-credential': 'Invalid email or password'
    })
    firebaseError.value = firebaseHumanError(e)
  } finally {
    sendingLink.value = false
  }
}

const loggingIn = ref(false)
const logIn = async () => {
  try {
    loggingIn.value = true
    if (!state.email) throw new Error('No email')
    await signInWithEmailLink(getAuth(), state.email, window.location.href)
    const url = new URL(window.location.href)
    // if continueUrl, redirect to it
    if (url.searchParams.has('continueUrl')) {
      window.location.replace(url.searchParams.get('continueUrl')!)
    } else {
      // otherwise, redirect to admin dashboard
      window.location.replace('/admin/dashboard')
    }
    // dont reset loggingIn until we have redirected
    // loggingIn.value = false
  } catch (e) {
    console.error(e)
    const firebaseHumanError = useFirebaseHumanError({
      'auth/invalid-credential': 'Invalid email or password'
    })
    firebaseError.value = `${firebaseHumanError(e)}. Please try again.`
    loggingIn.value = false
  }
}

const shouldCollectEmail = ref(false)

const onsubmit = async (event: FormSubmitEvent<{ email: string }>) => {
  if (shouldCollectEmail.value) {
    await logIn()
  } else {
    await sendLink(event)
  }
}

onMounted(async () => {
  const auth = getAuth()
  if (isSignInWithEmailLink(auth, window.location.href)) {
    const maybeEmail = window.localStorage.getItem('emailForSignIn')
    if (maybeEmail && typeof maybeEmail === 'string') {
      state.email = maybeEmail
      await logIn()
    } else {
      shouldCollectEmail.value = true
    }
  } else {
    // check for email query param
    const maybeEmail = useRoute().query.email
    if (maybeEmail && typeof maybeEmail === 'string') {
      state.email = maybeEmail
    }
  }
})
</script>
